import React from "react";

import { FiInstagram } from "react-icons/fi";
import { RiFacebookFill } from "react-icons/ri";
import { SlLocationPin } from "react-icons/sl";
import { BiPhone } from "react-icons/bi";
import { Link } from "react-router-dom";

const Footer = ({ outlet }) => {
  let hours = outlet.operational_hours;

  // for rights reserved
  const currentYear = new Date().getFullYear();
  return (
    <footer className="border-t-1 gap-x-14 gap-y-24 border-neutral-200 p-10 pb-7 pt-16 leading-8 sm:grid sm:grid-cols-4 lg:p-24 lg:pb-7 ">
      {/* trisara */}
      <section className="pb-8 sm:col-span-2 lg:col-span-1">
        {outlet.logo ? (
          <>
            <img
              className="w-9/12"
              src={
                outlet.franchise ===
                "https://api.trisaranepal.com/franchises/bakery/"
                  ? require("../../assets/images/baking factory footer-04.png")
                  : outlet.logo
              }
              alt="logo"
            />
          </>
        ) : (
          <img
            className="w-9/12"
            src={require("../../assets/images/trisara main.png")}
            alt="logo"
          />
        )}

        <h1 className="my-5 text-[16px] font-medium uppercase tracking-[9px] md:text-[20px] md:tracking-[14px]">
          {outlet.name}
        </h1>
        <p className="">
          {outlet.id === "j_pokhara"
            ? "At Jade City Hotel, we believe that travel is an opportunity to create memories that will last a lifetime. Through our legendary service, thoughtful amenities and commitment to craft, we inspire life's most meaningful moments, each and every day that changes everything."
            : "TRISARA, The Third Garden of Heaven, is a Picturesque Outdoor Restaurant offering the most scrumptious Food & Drinks along with Good Music and Great times."}
        </p>
        {window.location.pathname !== '/booking' &&
        <div className="">
          <a
            className="inline-block pr-2 pt-2 text-4xl"
            href={outlet.instagram_url}
            target="_blank"
            rel="noreferrer"
          >
            <FiInstagram />
          </a>
          <a
            className="inline-block pr-2 pt-2 text-4xl"
            href={outlet.facebook_url}
            target="_blank"
            rel="noreferrer"
          >
            <RiFacebookFill />
          </a>
        </div>}
      </section>
      {/*groups and events  */}
      <section className="pb-8 md:pl-6 pl-0 sm:col-span-2 lg:col-span-1">
        <h2 className="pb-2">Groups and Events</h2>
        <Link
          to="https://www.trisaranepal.com/events"
          target="_blank"
          rel="noreferrer"
          className="block"
        >
          Birthday Celebrations
        </Link>
        <Link
          to="https://www.trisaranepal.com/events"
          target="_blank"
          rel="noreferrer"
          className="block"
        >
          Private Events
        </Link>
        <Link
          to="https://www.trisaranepal.com/events"
          target="_blank"
          rel="noreferrer"
          className="block"
        >
          Corporate Events
        </Link>
        <Link
          to="https://www.trisaranepal.com/events"
          target="_blank"
          rel="noreferrer"
          className="block"
        >
          Family Events
        </Link>
        <Link
          to="https://www.trisaranepal.com/events"
          target="_blank"
          rel="noreferrer"
          className="block"
        >
          Other Events
        </Link>
      </section>
      {/* Operation Hour */}

      {outlet.id === "j_pokhara" ? (
        <section className="pb-8 sm:col-span-2 lg:col-span-1">
          <h2 className="pb-2">Our Services</h2>
          <ul className="list-disc pl-5">
            <li>24 hour front Desk & Room Services</li>
            <li>Stunning Lake and Mountain View rooms</li>
            <li>Balcony in every rooms</li>
            <li>Family Rooms available</li>
            <li>Free Wi-Fi</li>
            <li>Air conditioning</li>
            <li>Non Smoking Rooms</li>
          </ul>
        </section>
      ) : outlet.operational_hours ? (
        <section className="pb-8 sm:col-span-2 lg:col-span-1">
          <h2 className="pb-2">Operation Hour</h2>

          <p>
            <b>Sunday: </b> {hours.Sunday[0]} - {hours.Sunday[1]}
          </p>
          <p>
            <b>Monday: </b> {hours.Monday[0]} - {hours.Monday[1]}
          </p>
          <p>
            <b>Tuesday: </b> {hours.Tuesday[0]} - {hours.Tuesday[1]}
          </p>
          <p>
            <b>Wednesday: </b> {hours.Wednesday[0]} - {hours.Wednesday[1]}
          </p>
          <p>
            <b>Thursday: </b> {hours.Thursday[0]} - {hours.Thursday[1]}
          </p>
          <p>
            <b>Friday: </b> {hours.Friday[0]} - {hours.Friday[1]}
          </p>
          <p>
            <b>Saturday: </b> {hours.Saturday[0]} - {hours.Saturday[1]}
          </p>
        </section>
      ) : (
        ""
      )}
      {/* Contact Details + Careers */}
      <section className="pb-8 sm:col-span-2 lg:col-span-1">
        {outlet.location_url ? (
          <div className="pb-5">
            <h2 className="pb-2">Contact Details</h2>
            <Link
              to="https://www.google.com/maps/place/Jade+City+Hotel/@28.2085261,83.9573095,21z/data=!4m9!3m8!1s0x399595f9e1652011:0xa35e9f8b8a847279!5m2!4m1!1i2!8m2!3d28.2085524!4d83.9572487!16s%2Fg%2F11svsb37r4?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              <SlLocationPin className="mr-3 inline text-xl" />
              {outlet.location}
            </Link>
            <br />
            <a href={"tel:" + outlet.phone_number}>
              <BiPhone className="mr-3 inline text-xl" />
              {outlet.phone_number}
            </a>
          </div>
        ) : (
          ""
        )}
        <>
          <h2 className="pb-2">Careers</h2>
          <Link
            to="https://www.trisaranepal.com/careers"
            target="_blank"
            rel="noreferrer"
            className="block"
          >
            Vacancies (Work with us)
          </Link>
          <Link
            to="https://www.trisaranepal.com/careers"
            target="_blank"
            rel="noreferrer"
            className="block"
          >
            Internship
          </Link>
          <Link
            to="https://www.trisaranepal.com/careers"
            target="_blank"
            rel="noreferrer"
            className="block"
          >
            Training
          </Link>
          <Link
            to="https://www.trisaranepal.com/careers"
            target="_blank"
            rel="noreferrer"
            className="block"
          >
            Workshops
          </Link>
        </>
      </section>
      <p className="col-span-4 text-center text-sm">
        Copyright © {currentYear} Developed by Crocus Pearl Technologies | All
        rights reserved
      </p>
    </footer>
  );
};

export default Footer;
