import React from "react";
import Footer from "../../components/trisara/footer";
import BeBookingForm from "../../components/beForms/beBookingForm/beBookingForm";

const Booking = () => {
  const outlet = "";
  return (
    <>
      <div className="text-center relative pt-32 pb-10">
        <img
          className="absolute top-0 h-[100%] w-[100vw] -z-10 opacity-20"
          src={require("../../assets/images/jade room.jpg")}
          alt="Reservation Background"
        />
        <h1>Booking</h1>
      </div>
      <div className="booking-form-wrapper">
        <BeBookingForm/>
      </div>
      <Footer outlet={outlet} />
    </>
  );
};

export default Booking;
