import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";

// trisara
import TrisaraOutlet from "../pages/trisara/trisaraOutlet";

// general
import NotFound from "../pages/general/notFound";
import OpeningSoon from "../pages/general/openingSoon";

import ToTop from "./scrollToTop";
import Nav from "../components/nav";
import Booking from "../pages/general/booking";

const Routing = () => {
  return (
    <Router>
      <ToTop />
      <Nav />

      <Routes>
        {/* pages */}
        <Route exact path="/" element={<TrisaraOutlet />} />
        <Route exact path="/booking" element={<Booking />} />

        {/* general */}
        <Route exact path="/openingSoon" element={<OpeningSoon />} />

        {/* default routes for invalid links */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routing;
