import baseAxios from "axios";

import { API_URL } from "../../constants";

export const axios = baseAxios.create({
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
		Authorization: "Basic " + window.btoa("frontend:WWEQgN9.%AAw]GfR"),
	},
});
