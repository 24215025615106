// routes
import AppRouting from "./utils/routing";

// styles
import "./App.css";

import "./styles/styles.css";
import "./styles/smaller-responsive.css";
import "./styles/mobile-responsive.css";
import "./styles/be-style.css";

function App() {
  return <AppRouting />;
}

export default App;
