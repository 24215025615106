import React from "react";
import useAxios from "../../utils/axios/useAxios";

const LiveMusic = ({ outlet }) => {
	const axios = useAxios();

	const [liveMusic, setLiveMusic] = React.useState([]);

	React.useEffect(() => {
		axios
			.get(`/gallery/`, {
				params: {
					outlet: outlet.id,
					live_music: true,
				},
			})
			.then((response) => {
				setLiveMusic(response.data.slice(0, 4));
			});
	}, [axios, outlet.id]);
	return (
		<div className="live-music relative min-h-[52vh]">
			<video
				className={`video ${
					outlet.franchise ===
					"https://api.trisaranepal.com/franchises/jade/"
						? ""
						: "opacity-20"
				}`}
				src={require(
					outlet.franchise ===
						"https://api.trisaranepal.com/franchises/jade/"
						? "../../assets/videos/IMG_5002.mp4"
						: "../../assets/videos/video.mp4"
				)}
				muted
				autoPlay={true}
				loop
			></video>

			<div className="px-8 py-16 md:p-[100px] lg:grid lg:grid-cols-6 lg:gap-4">
				{outlet.franchise ===
					"https://api.trisaranepal.com/franchises/jade/" ||
				outlet.franchise ===
					"https://api.trisaranepal.com/franchises/bakery/" ? (
					""
				) : (
					<div className="detail lg:col-span-2 lg:p-10 xl:px-28 xl:py-20">
						<p className="pre-heading">Feedbacks</p>
						<h1>What's On</h1>
						<p className="live-music-text">
							{outlet.event_description}{" "}
						</p>
					</div>
				)}
				<div className="columns-2 md:columns-4 lg:col-span-4">
					{liveMusic &&
						liveMusic.map((item) => (
							<img
								key={item.id}
								className="w-full rounded-3xl py-2 sm:h-80 lg:h-128 2xl:h-[50vh]"
								src={item.thumbnail_url}
								alt="event singers"
							/>
						))}
				</div>
			</div>
		</div>
	);
};

export default LiveMusic;
