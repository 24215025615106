import { useEffect, useRef } from "react";

import { axios } from "./axios";

const useAxios = () => {
	// const interceptorRef = useRef(null);

	// const authHeader = "Basic " + window.btoa("frontend:WWEQgN9.%AAw]GfR");

	// useEffect(() => {
	// 	interceptorRef.current = axios.interceptors.request.use(
	// 		(config) => {
	// 			if (!config.headers["Authorization"]) {
	// 				config.headers["Authorization"] = authHeader;
	// 			}
	// 			return config;
	// 		},
	// 		(error) => Promise.reject(error)
	// 	);

	// 	return () => {
	// 		axios.interceptors.request.eject(interceptorRef.current);
	// 	};
	// }, [authHeader]);

	return axios;
};

export default useAxios;
