import React, { useContext, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { MenuContext } from "react-flexible-sliding-menu";
import Franchise from "./popUps/franchise";

// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// for reservation form
import Reservation from "./popUps/jadeReservation";
import { Link } from "react-router-dom";

const SlideMenu = () => {
  // to close full page menu
  const { closeMenu } = useContext(MenuContext);

  // for reservation pop up
  const ReserationPopUp = withReactContent(Swal);

  const showReservationPopup = () => {
    ReserationPopUp.fire({
      title: "Reserve a Table",
      html: <Reservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const [dropdown, setDropdown] = useState(false);

  // franchise pop up
  const FranchisePopUp = withReactContent(Swal);
  const showFranchisePopup = () => {
    FranchisePopUp.fire({
      // title: "Franchising Initial Inquiry Form",
      title: "Franchising Inquiry",
      html: <Franchise />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <nav className="menu table h-screen w-screen">
      {/* <img
        className="absolute w-40 inset-10"
        src={require("../assets/images/trisara main.png")}
        alt="trisara logo"
      /> */}
      <div className="nav-links table-cell p-10 align-middle text-xl leading-[6vh]">
        <a className="block" href="/">
          Home
        </a>
        <div className="dropdown">
          <button>Outlet</button>
          <ul className="dropdown-options pl-8">
            <li>
              <a
                href="https://www.trisaranepal.com/trisara"
                target="_blank"
                rel="noreferrer"
              >
                Trisara Restaurant
              </a>
            </li>
            <li>
              <a
                href="https://www.trisaranepal.com/peros"
                target="_blank"
                rel="noreferrer"
              >
                Peros
              </a>
            </li>
            <li>
              <a
                href="https://www.trisaranepal.com/bakery  "
                target="_blank"
                rel="noreferrer"
              >
                Bakery
              </a>
            </li>
            <li>
              <a href="/">Jade City Hotel</a>
            </li>
          </ul>
        </div>
        <a
          className="block"
          href="https://www.trisaranepal.com/openingSoon"
          target="_blank"
          rel="noreferrer"
        >
          Achievements
        </a>
        <a
          onClick={() => {
            showFranchisePopup();
          }}
          onMouseEnter={() => setDropdown(false)}
        >
          Franchise
        </a>
        <a
          className="block"
          href="https://www.trisaranepal.com/careers"
          target="_blank"
          rel="noreferrer"
        >
          Careers
        </a>
        {/* <button
          className="mt-5 w-full rounded-md bg-black py-1 font-semibold text-white transition-all duration-500 ease-in-out hover:bg-[#ebf3e8] hover:text-black hover:outline hover:outline-2 hover:outline-black"
          onClick={() => {
            showReservationPopup();
          }}
        >
          Reserve Now
        </button> */}

        <a
          className="booking-btn 2xl:absolute right-5 rounded-md bg-black px-11 py-2 font-semibold text-white transition-all duration-500 ease-in-out hover:bg-[#ebf3e8] hover:text-black hover:outline hover:outline-2 hover:outline-black"
          href="/booking"
        >
          Book Now
        </a>
      </div>

      {/* close full page menu */}
      <span className="toggleMenu" onClick={closeMenu}>
        <RxCross1 />
      </span>
    </nav>
  );
};

export default SlideMenu;
